<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'PrivacyPolicy',

    metaInfo: {
      meta: [
        { name: 'robots', content: 'noindex' },
      ],
    },

    extends: View,

    mixins: [
      LoadSections([
        'PrivacyPolicy',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'PrivacyPolicy',
      },
    },
  }
</script>
